// material-ui
import { Button, Link, CardMedia, Stack, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';

// assets
import { Logout } from 'iconsax-react';
import avatar from 'assets/images/analytics/whats.png';
import AnimateButton from 'components/@extended/AnimateButton';

// ==============================|| DRAWER CONTENT - NAV CARD ||============================== //

const NavCard = () => (
  <MainCard sx={{ bgcolor: 'secondary.lighter', m: 1 }}>
    <CardMedia component="img" image={avatar} height="100" sx={{objectFit: "contain" }}/>
    <Stack alignItems="center" spacing={2.5}>
      <Stack alignItems="center">
        <Typography variant="h5">Altern</Typography>
        <Typography variant="h6" color="secondary">
          Suporte WhatsApp
        </Typography>
      </Stack>
      <AnimateButton>
        <Button
          style={{ textTransform: 'none' }}
          variant="shadow"
          color="warning"
          component={Link}
          href="https://api.whatsapp.com/send?phone=5511933189622"
          target="_blank"
          startIcon={<Logout />}
        >
          Entrar em contato
        </Button>
      </AnimateButton>
    </Stack>
  </MainCard>
);

export default NavCard;
