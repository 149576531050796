export const APP_DEFAULT_PATH = '/dashboard';
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 280;
export const MINI_DRAWER_WIDTH = 0;
export const HEADER_HEIGHT = 74;
export const APP_BASE_NAME = '/';

const config = {
  fontFamily: `Inter var`,
  menuOrientation: 'vertical',
  menuCaption: true,
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
  themeContrast: false
};

export default config;