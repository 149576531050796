import PropTypes from 'prop-types';
import '../../../src/assets/css/numbers.css'
import logo from './../../assets/images/logo.png'

const LogoSection = () => (
    <img src={logo} style={{width: 90, position: 'relative', left: '50%', marginLeft: -45, marginTop: 30, marginBottom: 30}} />
);

LogoSection.propTypes = {
  reverse: PropTypes.bool,
};

export default LogoSection;
