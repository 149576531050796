import { Home3, HomeTrendUp, Simcard1, WalletMoney, ProfileCircle, Profile2User, Stickynote, Instagram } from 'iconsax-react';

const dashboard = {
  id: 'group-dashboard',
  title: 'Navegação',
  icon: Home3,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: HomeTrendUp,
      breadcrumbs: false
    },
    {
      id: 'numeros',
      title: 'Números',
      type: 'item',
      url: '/numbers',
      icon: Simcard1,
    },
    {
      id: 'grupos',
      title: 'Grupo',
      type: 'item',
      url: '/groups',
      icon: Profile2User,
    },
    // {
    //   id: 'finance',
    //   title: 'Financeiro',
    //   type: 'item',
    //   url: '/finance',
    //   icon: WalletMoney,
    // },
    {
      id: 'influencers',
      title: 'Influencers',
      type: 'item',
      url: '/influencers',
      icon: Instagram,
    },
    {
      id: 'reports',
      title: 'Relatórios',
      type: 'item',
      url: '/reports',
      icon: Stickynote,
    },
    {
      id: 'profile',
      title: 'Perfil',
      type: 'item',    
      url: '/profile/personal',
      icon: ProfileCircle,
      breadcrumbs: false
    }
    // {
    //   id: 'reports',
    //   title: 'Relatórios',
    //   type: 'item',
    //   url: '/reports',
    //   icon: icons.documentfilter,
    //   // breadcrumbs: false
    // },
    // {
    //   id: 'finance',
    //   title: 'Financeiro',
    //   type: 'item',
    //   url: '/finance',
    //   icon: EmptyWallet,
    //   // breadcrumbs: false
    // },
    // {
    //   id: 'faq',
    //   title: 'Faq',
    //   type: 'item',
    //   url: '/faq',
    //   icon: Book1,
    // },
  ]
};

export default dashboard;