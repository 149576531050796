import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Button, Stack, Table, TableCell, TableBody, TableHead, TableRow, TableContainer, Tooltip, TextField, Grid, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, useMediaQuery, useTheme } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import MainCard from 'components/MainCard';
import 'react-toastify/dist/ReactToastify.css';
import { SearchNormal1, Edit } from 'iconsax-react';
import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';
import Loader from 'components/Loader';
import { TablePagination } from 'components/third-party/react-table';
import IconButton from 'components/@extended/IconButton';
import Api from 'services/Api';
import { FormControlLabel } from '../../node_modules/@mui/material/index';

const Influencers = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [influencers, setInfluencers] = useState([]);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const [redirect, setRedirect] = useState(false);

  const [name, setName] = useState('');
  const [instagram, setInstagram] = useState('');
  const [message, setMessage] = useState('');
  const [externalLink, setExternalLink] = useState('');

  const [updateId, setUpdateId] = useState('');
  const [influencerStatus, setInfluencerStatus] = useState('');
  
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const userData = localStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user ? user.id : null;
  const token = localStorage.getItem('token');

  useEffect(() => {
    if(redirect == false) {
      setExternalLink('')
    }
  }, [redirect])

  const resetForm = () => {
    setName('');           
    setInstagram('');      
    setMessage('');    
    setExternalLink('');
  };
  
  const handleUpdateOpenDialog = (influencer) => {
    setSelectedInfluencer(influencer);
    setUpdateDialogOpen(true);
  };

  useEffect(() => {
    fetchInfluencers();
    setLoading(false);
  }, [pageIndex, pageSize]);

  const toggleRowExpansion = (rowId) => {
    setExpandedRowId(expandedRowId === rowId ? null : rowId);
  };

  const fetchInfluencers = async () => {
    try {
      if (!user_id || !token) return;
  
      const response = await Api.get(`/influencers`, {
        params: { user_id }
      });
  
      const sortedInfluencers = response.data.sort((a, b) => b.status - a.status);
      setInfluencers(sortedInfluencers);
  
    } catch (error) {
      console.error('Error fetching influencers:', error);
    }
  };

  const create = async () => {
    if (name == '' || instagram == '') {
      toast.error('Instagram e nome são campos obrigatórios.')
      return
    }

    try {
      await Api.post('/influencers', {
        user_id: user_id,
        message: message,       
        name: name,             
        instagram: instagram, 
        external: externalLink
      })
      .then(response => {
        if(response.status == 201) {
          resetForm()
          fetchInfluencers()
          setOpenModalCreate(false)
          toast.success(response.data.message)
        }
      })
    } catch (error) {
      console.error('Error creating influencer:', error);
      setOpenModalCreate(false)
      toast.error('Ocorreu um erro tente novamente.')
    }
  }

  const update = async () => {
    if (name == '' || instagram == '') {
      toast.error('Instagram e nome são campos obrigatórios.')
      return
    }

    try {
      await Api.put('/influencers', {
        id: updateId,
        message: message,       
        name: name,             
        instagram: instagram, 
        external: externalLink,
        status: influencerStatus == false ? 0 : 1
      })
      .then(response => {
        if(response.status == 200) {
          resetForm()
          fetchInfluencers()
          setOpenModalEdit(false)
          toast.success(
            <>
              {response.data.message} 
              <br />
              <h3>({response.data.influencer.name})</h3>
            </>
          )
        }
      })
    } catch (error) {
      console.error('Error updating influencer:', error);
      setOpenModalCreate(false)
      toast.error('Ocorreu um erro tente novamente mais tarde.')
    }
  }

  const setOpenModalEditAndPassData = (data) => {
    const { id, name, instagram, message, external, status } = data;
    
    external ? setRedirect(true) : setRedirect(false);
    setUpdateId(id);
    setName(name);           
    setInstagram(instagram);      
    setMessage(message);    
    setExternalLink(external);
    setInfluencerStatus(status)
    setOpenModalEdit(true)
  } 

  const columns = useMemo(() => [
    {
      header: 'Nome',
      accessorKey: 'name',
      cell: ({ getValue }) => <strong>{getValue()}</strong>,
    },
    {
      header: 'Mensagem',
      accessorKey: 'message',
      cell: ({ getValue }) => <strong>{getValue()}</strong>,
    },
    {
      header: 'Instagram',
      accessorKey: 'instagram',
      cell: ({ getValue }) => <div>{getValue()}</div>,
    },
    {
      header: 'Link',
      accessorKey: 'link',
      cell: ({ getValue }) => <div>https://altern.com.br/i/{getValue()}</div>,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ getValue }) => (
        <div style={{ color: getValue() ? 'green' : 'red' }}>
          {getValue() ? 'Ativo' : 'Inativo'}
        </div>
      ),
      sortingFn: 'basic',
    },
    {
      header: 'Ações',
      accessorKey: 'actions',
      cell: ({ row }) => (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
          <Tooltip title="Editar">
            <IconButton color="secondary" onClick={() => setOpenModalEditAndPassData(row.original)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ], [expandedRowId]);

  const table = useReactTable({
    data: influencers,
    columns,
    state: {
      pageIndex,
      pageSize,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount,
    manualPagination: true,
    enableSorting: true,
    initialState: {
      sorting: [{ id: 'status', desc: true }],
    },
  });

  return (
    <>
      <MainCard content={false}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ padding: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} xl={3}>
              <TextField placeholder="Influencer" onChange={(e) => setGlobalFilter(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} xl={7}>
              <Button variant="contained" size="large" style={{ height: 48 }}>
                <SearchNormal1 />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} xl={2}>
              <Button variant="contained" color="success" onClick={() => setOpenModalCreate(true)} size="large" style={{ marginTop: 6, width: '100%', textTransform: 'capitalize' }}>
                Novo
              </Button>
            </Grid>
          </Grid>
        </Stack>

        {loading ? (
          <Loader />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {header.isPlaceholder ? null : header.column.columnDef.header}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {cell.column.columnDef.cell(cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {expandedRowId === row.original.id && (
                      <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                        <TableCell colSpan={columns.length}>
                          <CustomerView data={row.original} />
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box sx={{ p: 2 }}>
          <TablePagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            pageCount={pageCount}
          />
        </Box>
      </MainCard>

      <Dialog open={openModalCreate} onClose={() => setOpenModalCreate(false)}>
        <DialogTitle>{"Criação de link para influencer"}</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" label="Nome" type="text" fullWidth onChange={value => setName(value.target.value)} />
          <TextField margin="dense" label="Instagram" type="text" fullWidth onChange={value => setInstagram(value.target.value)} />
          <TextField margin="dense" label="Mensagem" type="text" fullWidth multiline rows={4} onChange={value => setMessage(value.target.value)} />
          <FormControlLabel
            control={
              <Switch
                checked={redirect}
                onChange={() => setRedirect(!redirect)}
              />
            }
            label={redirect ? 'Redirecionamento habilitado' : 'Redirecionamento desabilitado'}
          />
          {
            redirect ?
            <TextField margin="dense" label="Redirecionamento" type="text" fullWidth onChange={value => setExternalLink(value.target.value)} /> : null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenModalCreate(false); resetForm(); }} color="secondary">Cancelar</Button>
          <Button onClick={() => create()} color="primary">Enviar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openModalEdit} onClose={() => setOpenModalEdit(false)}>
        <DialogTitle>{"Edição de link para influencer"}</DialogTitle>
        <DialogContent>
          <TextField value={name} autoFocus margin="dense" label="Nome" type="text" fullWidth onChange={value => setName(value.target.value)} />
          <TextField value={instagram} margin="dense" label="Instagram" type="text" fullWidth onChange={value => setInstagram(value.target.value)} />
          <TextField value={message} margin="dense" label="Mensagem" type="text" fullWidth multiline rows={4} onChange={value => setMessage(value.target.value)} />
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={isMobile ? "column" : "row"} 
            gap={2} 
            mt={2}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={redirect}
                  onChange={() => setRedirect(!redirect)}
                />
              }
              label={redirect ? 'Redirecionamento habilitado' : 'Redirecionamento desabilitado'}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={influencerStatus}
                  onChange={() => setInfluencerStatus(!influencerStatus)}
                />
              }
              label={influencerStatus ? 'Ativo' : 'Inativo'}
            />
          </Box>
          {
            redirect ?
            <TextField value={externalLink} margin="dense" label="Redirecionamento" type="text" fullWidth onChange={value => setExternalLink(value.target.value)} /> : null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenModalEdit(false); resetForm(); }} color="secondary">Cancelar</Button>
          <Button onClick={() => update()} color="primary">Enviar</Button>
        </DialogActions>
      </Dialog>


      <ToastContainer />
    </>
  );
};

export default Influencers;
