import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import { render } from 'react-dom';
import CheckoutSuccess from 'pages/checkout-success';
import Influencers from 'pages/influencers';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Numbers = Loadable(lazy(() => import('pages/numbers')));
const Groups = Loadable(lazy(() => import('pages/groups')));
const UpdateLink = Loadable(lazy(() => import('pages/update-link')));
const NotFoundPage = Loadable(lazy(() => import('pages/not-found-page')));

const Profile = Loadable(lazy(() => import('pages/profile/index')));
const TabPersonal = Loadable(lazy(() => import('pages/profile/TabPersonal')));
const TabPassword = Loadable(lazy(() => import('pages/profile/TabPassword')));

const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const Forgot = Loadable(lazy(() => import('pages/auth/forgot')));
const CheckEmail = Loadable(lazy(() => import('pages/auth/check-email')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));

const SamplePage = Loadable(lazy(() => import('pages/sample-page')));

const Checkout = Loadable(lazy(() => import('pages/checkout')));

const LandingPage = Loadable(lazy(() => import('pages/landing-page')));
const Finance = Loadable(lazy(() => import('pages/finance')));
const Reports = Loadable(lazy(() => import('pages/reports')));

const MainRoutes = {

  path: '/',
  children: [
    {
      path: '/',
      element: <CommonLayout />,
      children: [
        {
          path: '/',
          element: <LandingPage />
        },
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'reset-password/:token',
          element: <ResetPassword />
        },
        {
          path: 'forgot',
          element: <Forgot />
        },
        {
          path: 'checkout',
          element: <Checkout />
        },
        {
          path: 'check-email',
          element: <CheckEmail />
        },
        {
          path: 'checkout-success',
          element: <CheckoutSuccess />
        },
        {
          path: '*',
          element: <NotFoundPage />
        },
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'dashboard',
          element: <DashboardDefault />
        },
        {
          path: 'numbers',
          element: <Numbers />
        },
        {
          path: 'groups',
          element: <Groups />
        },
        {
          path: 'finance',
          element: <Finance />
        },
        {
          path: 'reports',
          element: <Reports />
        },
        {
          path: 'update-link',
          element: <UpdateLink />
        },
        {
          path: 'influencers',
          element: <Influencers />
        },
        {
          path: 'profile',
          element: <Profile />,
          children: [
            {
              path: 'personal',
              element: <TabPersonal />
            },
            {
              path: 'password',
              element: <TabPassword />
            },
          ]
        },


        // {
        //   path: 'user',
        //   element: <UserProfile />,
        //   children: [
        //     {
        //       path: 'personal',
        //       element: <UserTabPersonal />
        //     },
        //     {
        //       path: 'payment',
        //       element: <UserTabPayment />
        //     },
        //     {
        //       path: 'password',
        //       element: <UserTabPassword />
        //     },
        //     {
        //       path: 'settings',
        //       element: <UserTabSettings />
        //     }
        //   ]
        // }
      ]
    },
  ]
};

export default MainRoutes;
